$body-bg: #000;
$theme-colors: (
	"primary": #9a8446
);

$link-color: #9a8446;
$link-hover-color: #66572e;


$body-bg: #eaeaea;

$container-max-widths: (
md: 720px,
xl: 800px) !default;


.component-info {
	display: none;
	//display: block;
}

html {
	font-family: 'Roboto', sans-serif;
}

td:first-child {
	text-align: center;
}

td:nth-child(1n+3) {
	text-align: right;
}

/* Navbar */
.navbar-light {
	min-height: 100px;
	position: fixed;
	opacity: 0.9;
	background-color: #f8f9fa !important;
}

a {
	text-decoration: none !important;
}

.form-control {
	background-color: #fff !important;
}

.form-label {
	margin-top: 18px;
}

.nav-link {
	font-size: 1.1em !important;
	text-transform: uppercase;
}

.navbar-brand {
	color: map-get($theme-colors, "primary") !important;
}

.knob {
	border-left: 2px solid map-get($theme-colors, "primary");
	background-color: #e6e3dd;
	margin-top: 20px;
	padding: 20px;
}

.alert {
	border: 0rem !important;
	border-left: 2px solid map-get($theme-colors, "primary") !important;
	border-radius: 0rem !important;
	margin-bottom: 20px !important;
}

.alert > p {
	margin-bottom: 0;
}

.primary {
	color: map-get($theme-colors, "primary");
}

h1 {
	color: map-get($theme-colors, "primary");
	font-weight: 300 !important;
	font-size: 3.0em !important;
	//font-family: 'Lobster', cursive;
	font-family: 'Merriweather', serif;
	margin-bottom: 0.6em !important;
	margin-top: 0.2em !important;
}

h2 {
	font-size: 1.5em;
}

h3 {
	margin-top: 20px !important;
	font-family: 'Roboto', sans-serif;
	font-size: 1.6em !important;
	font-weight: 300 !important;
	color: grey;
}

.tag {
	font-weight: 100;
	color: #333;
	letter-spacing: 2px;
	text-transform: uppercase;
	//background-color: map-get($theme-colors, "primary");
	//padding: 4px 10px 5px 10px;
}

thead {
	background-color: #555;
	color: #ddd;
}

.btn {
	border-radius: 0 !important;
	font-weight: 200 !important;
	letter-spacing: 0.3px;
	text-transform: uppercase;

}

p {
	font-size: 1.1em !important;
	letter-spacing: 0.3px;
	font-weight: 300;
	line-height: 1.8em !important;
}

.font-weight-bold {
	color: map-get($theme-colors, "primary");
}

#page-container {
	position: relative;
	min-height: 100vh;
}

#content-wrap {
	padding-bottom: 38.0rem;
	/* Footer height */
}

#footer {
	position: absolute;
	bottom: 0;
	width: 100%;
	color: white;
}

#footer h3 {
	font-size: 1.5em !important;
	font-weight: 100;
	letter-spacing: 1px;
}

#footer p {
	font-size: 0.9em !important;
}

#footer > .top {
	//background-color: rgba(132, 117, 85, 0.7);
	background-color: #444;
	padding-top: 60px;
	padding-bottom: 45px;
}

#footer > .bottom {
	//background-color: map-get($theme-colors, "primary");
	background-color: #222;
	padding: 10px;
}

// Import Bootstrap and its default variables
@import "./node_modules/bootstrap/scss/bootstrap";
//@import "~bootstrap/scss/bootstrap.scss";
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap');
